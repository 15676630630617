import React from "react";

const data = [
  {
    title: "Hỏi giùm người khác",
    level: "L1",
    color: "#000000",
    backgroundColor: "#e8eaed",
  },
  {
    title: "Không nghe máy",
    level: "L1",
    color: "#000000",
    backgroundColor: "#e8eaed",
  },
  {
    title: "Thuê bao/sai số",
    level: "L1",
    color: "#000000",
    backgroundColor: "#e8eaed",
  },
  {
    title: "Không có nhu cầu",
    level: "L1",
    color: "#000000",
    backgroundColor: "#e8eaed",
  },
  {
    title: "Chưa phản hồi",
    level: "L2",
    color: "#b10202",
    backgroundColor: "#ffcfc9",
  },
  {
    title: "Không phản hồi",
    level: "L2",
    color: "#b10202",
    backgroundColor: "#ffcfc9",
  },
  {
    title: "Hẹn gọi lại",
    level: "L2",
    color: "#b10202",
    backgroundColor: "#ffcfc9",
  },
  {
    title: "Muốn học thử",
    level: "L2",
    color: "#b10202",
    backgroundColor: "#ffcfc9",
  },
  {
    title: "Không add Zalo",
    level: "L2",
    color: "#b10202",
    backgroundColor: "#ffcfc9",
  },
  {
    title: "Đang xem thông tin",
    level: "L3",
    color: "#5a3286",
    backgroundColor: "#e6cff2",
  },
  {
    title: "Đợi làm test",
    level: "L3",
    color: "#5a3286",
    backgroundColor: "#e6cff2",
  },
  {
    title: "Đang chọn lịch",
    level: "L4",
    color: "#2a3243",
    backgroundColor: "#ddebff",
  },
  {
    title: "Không ở HN, muốn học off",
    level: "L4",
    color: "#2a3243",
    backgroundColor: "#ddebff",
  },
  {
    title: "Tư vấn Zalo",
    level: "L4",
    color: "#2a3243",
    backgroundColor: "#ddebff",
  },
  {
    title: "Phân vân onl, off",
    level: "L4",
    color: "#2a3243",
    backgroundColor: "#ddebff",
  },
  {
    title: "Hẹn tháng sau, ra Tết...",
    level: "L4",
    color: "#2a3243",
    backgroundColor: "#ddebff",
  },
  {
    title: "Chuẩn bị ĐK",
    level: "L4",
    color: "#2a3243",
    backgroundColor: "#ddebff",
  },
  {
    title: "Đã đăng ký",
    level: "L8",
    color: "#ffcfc9",
    backgroundColor: "#b10202",
  },
  {
    title: "Khách Combo, Học lên",
    level: "L12",
    color: "#473821",
    backgroundColor: "#ffe5a0",
  },
];

const renderItem = (item) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginBlock: "12px",
    }}
  >
    <div
      style={{
        minWidth: "180px",
      }}
    >
      {item.title}
    </div>
    <div
      style={{
        backgroundColor: item.backgroundColor,
        color: item.color,
        borderRadius: "100px",
        padding: "0 8px",
        height: "20px",
        width: "100px",
        fontSize: "14px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>{item.level}</div>
    </div>
  </div>
);

export const LevelStatusList = () => {
  return (
    <div
      style={{
        maxHeight: "300px",
        overflowY: "scroll",
      }}
    >
      {data.map((item) => (
        <div>{renderItem(item)}</div>
      ))}
    </div>
  );
};
