import React from "react";

const data = [
  {
    title: "Đang học",
    color: "#5a3286",
    backgroundColor: "#e6cff2",
  },
  {
    title: "Bảo lưu",
    color: "#11734b",
    backgroundColor: "#d4edbc",
  },
  {
    title: "Đã kết thúc",
    color: "#bfe0f6",
    backgroundColor: "#0a53a8",
  },
  {
    title: "Bỏ học",
    color: "#ffcfc9",
    backgroundColor: "#b10202",
  },
];

const renderItem = (item) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginBlock: "12px",
    }}
  >
    <div
      style={{
        minWidth: "180px",
      }}
    >
      {item.title}
    </div>
    <div
      style={{
        backgroundColor: item.backgroundColor,
        color: item.color,
        borderRadius: "100px",
        padding: "0 8px",
        height: "20px",
        width: "100px",
        fontSize: "14px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    ></div>
  </div>
);

export const LevelStudyStatusList = () => {
  return (
    <div
      style={{
        maxHeight: "300px",
        overflowY: "scroll",
      }}
    >
      {data.map((item) => (
        <div>{renderItem(item)}</div>
      ))}
    </div>
  );
};
